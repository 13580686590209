import React, { useState, useRef } from "react"

// Components
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import Seo from "../../../components/seo"
import ContentNavigation from "../../../components/scrollspy"
import ogImage from "../../../images/seo/feeds/feeds.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Preview
import BaseUserFeedPreview from "../../../library/components/feed/react/_preview/base/user_feed"
import SmUserFeedPreview from "../../../library/components/feed/react/_preview/sm/user_feed"

import BaseUserFeedNestedPreview from "../../../library/components/feed/react/_preview/base/user_feed-nested"
import SmUserFeedNestedPreview from "../../../library/components/feed/react/_preview/sm/user_feed-nested"

import BaseActivityFeedPreview from "../../../library/components/feed/react/_preview/base/activity_feed"
import SmActivityFeedPreview from "../../../library/components/feed/react/_preview/sm/activity_feed"

import BaseColoredActivityFeedPreview from "../../../library/components/feed/react/_preview/base/colored-activity_feed"
import SmColoredActivityFeedPreview from "../../../library/components/feed/react/_preview/sm/colored-activity_feed"

import BaseChangelogFeedPreview from "../../../library/components/feed/react/_preview/base/changelog_feed"
import SmChangelogFeedPreview from "../../../library/components/feed/react/_preview/sm/changelog_feed"

import BaseAltChangelogFeedPreview from "../../../library/components/feed/react/_preview/base/alt-changelog_feed"
import SmAltChangelogFeedPreview from "../../../library/components/feed/react/_preview/sm/alt-changelog_feed"

import HeroFeedComponent from "../../../library/components/feed/react/_preview/heroFeedComponent"

// All
// Base
const BaseUserFeedJsx = require("!!raw-loader!../../../library/components/feed/react/base/user_feed.jsx")
const BaseUserFeedHTML = require("!!raw-loader!../../../library/components/feed/html/base/user_feed.html")

const BaseUserFeedNestedJsx = require("!!raw-loader!../../../library/components/feed/react/base/user_feed-nested.jsx")
const BaseUserFeedNestedHTML = require("!!raw-loader!../../../library/components/feed/html/base/user_feed-nested.html")

const BaseActivityFeedJsx = require("!!raw-loader!../../../library/components/feed/react/base/activity_feed.jsx")
const BaseActivityFeedHTML = require("!!raw-loader!../../../library/components/feed/html/base/activity_feed.html")

const BaseColoredActivityFeedJsx = require("!!raw-loader!../../../library/components/feed/react/base/colored-activity_feed.jsx")
const BaseColoredActivityFeedHTML = require("!!raw-loader!../../../library/components/feed/html/base/colored-activity_feed.html")

const BaseChangelogFeedJsx = require("!!raw-loader!../../../library/components/feed/react/base/changelog_feed.jsx")
const BaseChangelogFeedHTML = require("!!raw-loader!../../../library/components/feed/html/base/changelog_feed.html")

const BaseAltChangelogFeedJsx = require("!!raw-loader!../../../library/components/feed/react/base/alt-changelog_feed.jsx")
const BaseAltChangelogFeedHTML = require("!!raw-loader!../../../library/components/feed/html/base/alt-changelog_feed.html")

// Sm
const SmUserFeedJsx = require("!!raw-loader!../../../library/components/feed/react/sm/user_feed.jsx")
const SmUserFeedHTML = require("!!raw-loader!../../../library/components/feed/html/sm/user_feed.html")

const SmUserFeedNestedJsx = require("!!raw-loader!../../../library/components/feed/react/sm/user_feed-nested.jsx")
const SmUserFeedNestedHTML = require("!!raw-loader!../../../library/components/feed/html/sm/user_feed-nested.html")

const SmActivityFeedJsx = require("!!raw-loader!../../../library/components/feed/react/sm/activity_feed.jsx")
const SmActivityFeedHTML = require("!!raw-loader!../../../library/components/feed/html/sm/activity_feed.html")

const SmColoredActivityFeedJsx = require("!!raw-loader!../../../library/components/feed/react/sm/colored-activity_feed.jsx")
const SmColoredActivityFeedHTML = require("!!raw-loader!../../../library/components/feed/html/sm/colored-activity_feed.html")

const SmChangelogFeedJsx = require("!!raw-loader!../../../library/components/feed/react/sm/changelog_feed.jsx")
const SmChangelogFeedHTML = require("!!raw-loader!../../../library/components/feed/html/sm/changelog_feed.html")

const SmAltChangelogFeedJsx = require("!!raw-loader!../../../library/components/feed/react/sm/alt-changelog_feed.jsx")
const SmAltChangelogFeedHTML = require("!!raw-loader!../../../library/components/feed/html/sm/alt-changelog_feed.html")

const FeedsPage = () => {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "BaseUserFeed",
      title: "User Feed Base",
      active_tab: 1,
    },
    {
      component_name: "SmUserFeed",
      title: "User Feed Sm",
      active_tab: 1,
    },
    {
      component_name: "BaseUserFeedNested",
      title: "Nested User Feed Base",
      active_tab: 1,
    },
    {
      component_name: "SmUserFeedNested",
      title: "Nested User Feed Sm",
      active_tab: 1,
    },
    {
      component_name: "BaseActivityFeed",
      title: "Activity Feed Base",
      active_tab: 1,
    },
    {
      component_name: "SmActivityFeed",
      title: "Activity Feed Sm",
      active_tab: 1,
    },
    {
      component_name: "BaseColoredActivityFeed",
      title: "Colored Activity Feed Base",
      active_tab: 1,
    },
    {
      component_name: "SmColoredActivityFeed",
      title: "Colored Activity Feed Sm",
      active_tab: 1,
    },
    {
      component_name: "BaseChangelogFeed",
      title: "Changelog Feed Base",
      active_tab: 1,
    },
    {
      component_name: "SmChangelogFeed",
      title: "Changelog Feed Sm",
      active_tab: 1,
    },
    {
      component_name: "BaseAltChangelogFeed",
      title: "Alt Changelog Feed Base",
      active_tab: 1,
    },
    {
      component_name: "SmAltChangelogFeed",
      title: "Alt Changelog Feed Sm",
      active_tab: 1,
    },
  ])

  const wrapperRef = useRef(null)

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Feeds - WindUI Component Library"
        ogtitle="Tailwind CSS Feeds - WindUI Component Library"
        description="The feed component is a ui element that displays a stream of content (static or dynamic), in a chronological order. Built with Tailwind CSS by WindUI."
        ogdescription="The feed component is a ui element that displays a stream of content (static or dynamic), in a chronological order. Built with Tailwind CSS by WindUI."
        url="components/feeds/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Feeds, Feeds, Feed Component, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Feed</h1>
          <p>
            The feed component is a ui element that displays a stream of content
            (static or dynamic), such as comments, activity updates, posts, in a
            chronological order.
          </p>

          <div className="flex w-full justify-start rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 md:justify-center md:p-16">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <HeroFeedComponent />
            </section>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* User Feed Base */}
          <h3 id="BaseUserFeed">User feed</h3>
          <PreviewBlock
            id="BaseUserFeed"
            HtmlComponent={BaseUserFeedHTML.default}
            JsxComponent={BaseUserFeedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <BaseUserFeedPreview
                BaseUserFeed={
                  activeTabs[0].active_tab === 1
                    ? BaseUserFeedHTML.default
                    : BaseUserFeedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* User Feed Sm */}
          <h3 id="SmUserFeed">User feed (sm)</h3>
          <PreviewBlock
            id="SmUserFeed"
            HtmlComponent={SmUserFeedHTML.default}
            JsxComponent={SmUserFeedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <SmUserFeedPreview
                SmUserFeed={
                  activeTabs[1].active_tab === 1
                    ? SmUserFeedHTML.default
                    : SmUserFeedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* User Feed Nested Base */}
          <h3 id="BaseUserFeedNested">Nested user feed</h3>
          <PreviewBlock
            id="BaseUserFeedNested"
            HtmlComponent={BaseUserFeedNestedHTML.default}
            JsxComponent={BaseUserFeedNestedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <BaseUserFeedNestedPreview
                BaseUserFeedNested={
                  activeTabs[2].active_tab === 1
                    ? BaseUserFeedNestedHTML.default
                    : BaseUserFeedNestedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* User Feed Nested Sm */}
          <h3 id="SmUserFeedNested">Nested user feed (sm)</h3>
          <PreviewBlock
            id="SmUserFeedNested"
            HtmlComponent={SmUserFeedNestedHTML.default}
            JsxComponent={SmUserFeedNestedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <SmUserFeedNestedPreview
                SmUserFeedNested={
                  activeTabs[3].active_tab === 1
                    ? SmUserFeedNestedHTML.default
                    : SmUserFeedNestedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Activity Feed Base*/}
          <h3 id="BaseActivityFeed">Activity feed</h3>
          <PreviewBlock
            id="BaseActivityFeed"
            HtmlComponent={BaseActivityFeedHTML.default}
            JsxComponent={BaseActivityFeedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <BaseActivityFeedPreview
                BaseActivityFeed={
                  activeTabs[4].active_tab === 1
                    ? BaseActivityFeedHTML.default
                    : BaseActivityFeedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Activity Feed Sm*/}
          <h3 id="SmActivityFeed">Activity feed (sm)</h3>
          <PreviewBlock
            id="SmActivityFeed"
            HtmlComponent={SmActivityFeedHTML.default}
            JsxComponent={SmActivityFeedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <SmActivityFeedPreview
                SmActivityFeed={
                  activeTabs[5].active_tab === 1
                    ? SmActivityFeedHTML.default
                    : SmActivityFeedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Activity Colored Feed Base */}
          <h3 id="BaseColoredActivityFeed">Activity feed with color status</h3>
          <PreviewBlock
            id="BaseColoredActivityFeed"
            HtmlComponent={BaseColoredActivityFeedHTML.default}
            JsxComponent={BaseColoredActivityFeedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <BaseColoredActivityFeedPreview
                BaseColoredActivityFeed={
                  activeTabs[6].active_tab === 1
                    ? BaseColoredActivityFeedHTML.default
                    : BaseColoredActivityFeedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Activity Colored Feed Sm */}
          <h3 id="SmColoredActivityFeed">
            Activity feed with color status (sm)
          </h3>
          <PreviewBlock
            id="SmColoredActivityFeed"
            HtmlComponent={SmColoredActivityFeedHTML.default}
            JsxComponent={SmColoredActivityFeedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <SmColoredActivityFeedPreview
                SmColoredActivityFeed={
                  activeTabs[7].active_tab === 1
                    ? SmColoredActivityFeedHTML.default
                    : SmColoredActivityFeedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Changelog Feed Base */}
          <h3 id="BaseChangelogFeed">Changelog feed</h3>
          <PreviewBlock
            id="BaseChangelogFeed"
            HtmlComponent={BaseChangelogFeedHTML.default}
            JsxComponent={BaseChangelogFeedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <BaseChangelogFeedPreview
                BaseChangelogFeed={
                  activeTabs[8].active_tab === 1
                    ? BaseChangelogFeedHTML.default
                    : BaseChangelogFeedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Changelog Feed Sm */}
          <h3 id="SmChangelogFeed">Changelog feed (sm)</h3>
          <PreviewBlock
            id="SmChangelogFeed"
            HtmlComponent={SmChangelogFeedHTML.default}
            JsxComponent={SmChangelogFeedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <SmChangelogFeedPreview
                SmChangelogFeed={
                  activeTabs[9].active_tab === 1
                    ? SmChangelogFeedHTML.default
                    : SmChangelogFeedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Changelog Feed Alt Base */}
          <h3 id="BaseAltChangelogFeed">Changelog feed - alt version</h3>
          <PreviewBlock
            id="BaseAltChangelogFeed"
            HtmlComponent={BaseAltChangelogFeedHTML.default}
            JsxComponent={BaseAltChangelogFeedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <BaseAltChangelogFeedPreview
                BaseAltChangelogFeed={
                  activeTabs[10].active_tab === 1
                    ? BaseAltChangelogFeedHTML.default
                    : BaseAltChangelogFeedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Changelog Feed Alt Sm */}
          <h3 id="SmAltChangelogFeed">Changelog feed - alt version (sm)</h3>
          <PreviewBlock
            id="SmAltChangelogFeed"
            HtmlComponent={SmAltChangelogFeedHTML.default}
            JsxComponent={SmAltChangelogFeedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <SmAltChangelogFeedPreview
                SmAltChangelogFeed={
                  activeTabs[11].active_tab === 1
                    ? SmAltChangelogFeedHTML.default
                    : SmAltChangelogFeedJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>Use the feed component to:</p>
          <ul>
            <li>
              Display a stream of content in chronological order, allowing users
              to scroll through updates, user messages, or activities easily.
            </li>
            <li>
              Social media platforms, news websites, e-commerce sites, and other
              applications that require presenting dynamic and frequently
              updated content to users.
            </li>
            <li>
              To provide users with relevant and up-to-date information in a
              visually appealing and easy-to-read format
            </li>
          </ul>
        </Section>
        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            The feed UI component comes in 3 variations (user feed, activity
            feed and changelog) and 2 sizes (base and sm). All variations are
            implemented using the <code>&lt;ul&gt;</code> and{" "}
            <code>&lt;li&gt;</code> HTML tags and they can consist of the
            following elements:
          </p>
          <ul>
            <li>
              <strong>Header: </strong> with font size of 1.125rem (18px) for
              the user and the changelog feed (1rem for sm size) and 1rem (16px)
              for the activity feed (0.875rem for sm size).
            </li>
            <li>
              <strong>Text: </strong> with font size of 1rem (16px) for the user
              and the changelog feed (0.875rem for sm size) and 0.875rem (14px)
              for the activity feed (0.75rem for sm size).
            </li>
            <li>
              <strong>Icon: </strong> 1.25rem (30px) width and height (1rem for
              the sm size).
            </li>
            <li>
              <strong>User avatar: </strong>2rem(32px) width and height (1.5rem
              for the sm size).
            </li>
          </ul>
        </Section>
        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            When using the feed component, the proper accessibility roles to use
            depend on the type of content being displayed and the specific
            functionality of the component:
          </p>
          <ul>
            <li>
              <code>role=&quot;feed&quot;</code> : This role should be used on
              the container element for the feed to indicate that it contains a
              list of dynamic content items.
            </li>
            <li>
              <code>role=&quot;article&quot;</code> : This role should be used
              on individual content items within the feed to indicate that they
              are standalone items of content.
            </li>
            <li>
              <code>aria-label</code> : specifies the label for the feed.
            </li>
            <li>
              <code>aria-busy</code> : When busy, such as when comments are
              being added or removed from the feed, set aria-busy="true" during
              the update operation. Make sure it's reset to false when the
              operation is complete or the changes may not become visible.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}

export default FeedsPage
